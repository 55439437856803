import React from "react";

const AssemblyIcon: React.FC<IconProps> = ({ width }) => {
  return (
    <svg
      width={width || 50}
      viewBox="0 0 54 43"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <polygon
          id="path-nmkf2eheoy-1"
          points="0 41.9186047 54 41.9186047 54 0 0 0"
        ></polygon>
      </defs>
      <g
        id="Homepage"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="jintai_website_mobile"
          transform="translate(-103.000000, -1738.000000)"
        >
          <g id="About" transform="translate(31.000000, 975.000000)">
            <g id="Group-20" transform="translate(72.000000, 522.000000)">
              <g id="Group-42" transform="translate(0.000000, 241.598837)">
                <path
                  d="M30.6,18.0482857 C30.3151411,18.0482857 30.083148,18.2798623 30.083148,18.5642097 C30.083148,18.848557 30.3151411,19.0796212 30.6,19.0796212 C30.8848589,19.0796212 31.116852,18.848557 31.116852,18.5642097 C31.116852,18.2798623 30.8848589,18.0482857 30.6,18.0482857 M30.6,20.3604651 C29.6073567,20.3604651 28.8,19.5545581 28.8,18.5642097 C28.8,17.5733488 29.6073567,16.7674419 30.6,16.7674419 C31.5926433,16.7674419 32.4,17.5733488 32.4,18.5642097 C32.4,19.5545581 31.5926433,20.3604651 30.6,20.3604651"
                  id="Fill-1"
                  fill="#0469D5"
                ></path>
                <polygon
                  id="Fill-3"
                  fill="#0469D5"
                  points="30.15333 5.98837209 26.4 2.2424448 27.44667 1.19767442 31.2 4.94360171"
                ></polygon>
                <polygon
                  id="Fill-5"
                  fill="#0469D5"
                  points="32.2056565 4.79069767 31.2 3.78683151 33.7943435 1.19767442 34.8 2.20097279"
                ></polygon>
                <polygon
                  id="Fill-7"
                  fill="#0469D5"
                  points="30.1525549 11.9767442 26.4 8.49361042 29.0985929 5.98837209 30.1454456 6.96021756 28.4942977 8.49361042 31.2 11.0043487"
                ></polygon>
                <polygon
                  id="Fill-9"
                  fill="#0469D5"
                  points="32.1010739 10.7790698 31.0534996 9.8067636 32.7054438 8.27406151 30 5.76300384 31.0475744 4.79069767 34.8 8.27406151"
                ></polygon>
                <polygon
                  id="Fill-11"
                  fill="#0469D5"
                  points="32.027009 17.9651163 30.9794346 17.003845 32.7054438 15.4205747 30 12.9380155 31.0475744 11.9767442 34.8 15.4205747"
                ></polygon>
                <polygon
                  id="Fill-13"
                  fill="#0469D5"
                  points="29.0129032 17.9651163 26.4 15.5376012 28.9431141 13.1744186 29.9302109 14.091157 28.3736352 15.5376012 30 17.047859"
                ></polygon>
                <path
                  d="M27.7966166,25.1511628 C26.8967877,24.319061 26.4,23.2131749 26.4,22.0364716 C26.4,20.8597684 26.8967877,19.7538823 27.7966166,18.9224128 C28.2441361,18.508891 28.7662422,18.18642 29.3465121,17.9651163 L30,19.4257205 C29.6284357,19.5679872 29.2938225,19.7747481 29.0064246,20.0403125 C28.4288918,20.5733382 28.1107014,21.2821422 28.1107014,22.0364716 C28.1107014,22.790801 28.4288918,23.5002374 29.0064246,24.0332631 L27.7966166,25.1511628 Z"
                  id="Fill-15"
                  fill="#0469D5"
                ></path>
                <path
                  d="M33.4030055,25.1511628 L32.192277,24.0330663 C32.7702492,23.4999468 33.087997,22.7903856 33.087997,22.0359234 C33.087997,21.2814613 32.7702492,20.5725324 32.192277,20.0394129 C31.9046605,19.7744342 31.5711623,19.5676369 31.2,19.4253452 L31.8553548,17.9651163 C32.4353814,18.1870913 32.9558303,18.5083543 33.4030055,18.920684 C34.3042039,19.7529323 34.8,20.859013 34.8,22.0359234 C34.8,23.2128338 34.3042039,24.3189145 33.4030055,25.1511628"
                  id="Fill-17"
                  fill="#0469D5"
                ></path>
                <mask id="mask-nmkf2eheoy-2" fill="white">
                  <use xlinkHref="#path-nmkf2eheoy-1"></use>
                </mask>
                <g id="Clip-20"></g>
                <polygon
                  id="Fill-19"
                  fill="#0469D5"
                  mask="url(#mask-nmkf2eheoy-2)"
                  points="10.8 2.39534884 50.4 2.39534884 50.4 1.19767442 10.8 1.19767442"
                ></polygon>
                <polygon
                  id="Fill-21"
                  fill="#0469D5"
                  mask="url(#mask-nmkf2eheoy-2)"
                  points="47.3190147 25.1511628 46.0085566 24.36315 47.4539326 21.7551427 46.2269663 19.5411419 43.7730337 19.5411419 42.5460674 21.7551427 43.9914434 24.36315 42.6809853 25.1511628 40.8 21.7551427 42.899395 17.9651163 47.100605 17.9651163 49.2 21.7551427"
                ></polygon>
                <polygon
                  id="Fill-22"
                  fill="#0469D5"
                  mask="url(#mask-nmkf2eheoy-2)"
                  points="44.4 9.58139535 45.6 9.58139535 45.6 8.38372093 44.4 8.38372093"
                ></polygon>
                <path
                  d="M44.4,11.377763 L45.6,11.377763 L45.6,9.58139535 L44.4,9.58139535 L44.4,11.377763 Z M44.4,14.372093 L45.6,14.372093 L45.6,12.5757254 L44.4,12.5757254 L44.4,14.372093 Z"
                  id="Fill-23"
                  fill="#0469D5"
                  mask="url(#mask-nmkf2eheoy-2)"
                ></path>
                <polygon
                  id="Fill-24"
                  fill="#0469D5"
                  mask="url(#mask-nmkf2eheoy-2)"
                  points="44.4 16.7674419 45.6 16.7674419 45.6 15.5697674 44.4 15.5697674"
                ></polygon>
                <polygon
                  id="Fill-25"
                  fill="#0469D5"
                  mask="url(#mask-nmkf2eheoy-2)"
                  points="51.6 9.58139535 44.4 9.58139535 44.4 7.99733755 50.2088569 7.99733755 50.2088569 3.59302326 51.6 3.59302326"
                ></polygon>
                <path
                  d="M51.0002533,2.46175522 C50.7059519,2.46175522 50.4663571,2.7009193 50.4663571,2.99418605 C50.4663571,3.28795842 50.7059519,3.52661688 51.0002533,3.52661688 C51.2940481,3.52661688 51.5336429,3.28795842 51.5336429,2.99418605 C51.5336429,2.7009193 51.2940481,2.46175522 51.0002533,2.46175522 M51.0002533,4.79069767 C50.0074293,4.79069767 49.2,3.98522539 49.2,2.99418605 C49.2,2.00365233 50.0074293,1.19767442 51.0002533,1.19767442 C51.9925707,1.19767442 52.8,2.00365233 52.8,2.99418605 C52.8,3.98522539 51.9925707,4.79069767 51.0002533,4.79069767"
                  id="Fill-26"
                  fill="#0469D5"
                  mask="url(#mask-nmkf2eheoy-2)"
                ></path>
                <path
                  d="M1.47943584,29.7532663 L4.52056416,29.7532663 L4.52056416,23.1712632 C4.52056416,22.3860807 3.83824835,21.7467337 2.99970411,21.7467337 C2.16115988,21.7467337 1.47943584,22.3860807 1.47943584,23.1712632 L1.47943584,29.7532663 Z M6,31.1395349 L0,31.1395349 L0,23.1712632 C0,21.621415 1.34569484,20.3604651 2.99970411,20.3604651 C4.65371338,20.3604651 6,21.621415 6,23.1712632 L6,31.1395349 Z"
                  id="Fill-27"
                  fill="#0469D5"
                  mask="url(#mask-nmkf2eheoy-2)"
                ></path>
                <polygon
                  id="Fill-28"
                  fill="#0469D5"
                  mask="url(#mask-nmkf2eheoy-2)"
                  points="4.59583292 41.9186047 0.705045917 41.9186047 0 30.08175 1.478819 29.9876758 2.09795596 40.3914262 3.28586946 40.3914262 4.53066061 29.9418605 6 30.1275654"
                ></polygon>
                <polygon
                  id="Fill-29"
                  fill="#0469D5"
                  mask="url(#mask-nmkf2eheoy-2)"
                  points="1.2 41.9186047 6 41.9186047 6 40.7209302 1.2 40.7209302"
                ></polygon>
                <polygon
                  id="Fill-30"
                  fill="#0469D5"
                  mask="url(#mask-nmkf2eheoy-2)"
                  points="10.1175816 25.1511628 3.6 21.6145326 4.28299039 20.3604651 10.8 23.8970953"
                ></polygon>
                <path
                  d="M2.99969943,15.812991 C2.17433123,15.812991 1.50285543,16.4567843 1.50285543,17.2481254 L1.50285543,18.6815308 C1.50285543,19.4734483 2.17433123,20.1172415 2.99969943,20.1172415 C3.82566877,20.1172415 4.49714457,19.4734483 4.49714457,18.6815308 L4.49714457,17.2481254 C4.49714457,16.4567843 3.82566877,15.812991 2.99969943,15.812991 M2.99969943,21.5581395 C1.34535618,21.5581395 0,20.2676713 0,18.6815308 L0,17.2481254 C0,15.6625613 1.34535618,14.372093 2.99969943,14.372093 C4.65404268,14.372093 6,15.6625613 6,17.2481254 L6,18.6815308 C6,20.2676713 4.65404268,21.5581395 2.99969943,21.5581395"
                  id="Fill-31"
                  fill="#0469D5"
                  mask="url(#mask-nmkf2eheoy-2)"
                ></path>
                <polygon
                  id="Fill-32"
                  fill="#0469D5"
                  mask="url(#mask-nmkf2eheoy-2)"
                  points="1.2 17.9651163 7.2 17.9651163 7.2 16.7674419 1.2 16.7674419"
                ></polygon>
                <path
                  d="M49.799658,38.1519493 C49.3728292,38.1519493 49.0260308,38.4980756 49.0260308,38.9240773 C49.0260308,39.3500789 49.3728292,39.6968879 49.799658,39.6968879 C50.2264868,39.6968879 50.5739692,39.3500789 50.5739692,38.9240773 C50.5739692,38.4980756 50.2264868,38.1519493 49.799658,38.1519493 M49.799658,40.7209302 C48.8078282,40.7209302 48,39.9153503 48,38.9240773 C48,37.933487 48.8078282,37.127907 49.799658,37.127907 C50.7921718,37.127907 51.6,37.933487 51.6,38.9240773 C51.6,39.9153503 50.7921718,40.7209302 49.799658,40.7209302"
                  id="Fill-33"
                  fill="#0469D5"
                  mask="url(#mask-nmkf2eheoy-2)"
                ></path>
                <path
                  d="M30.600342,38.1519493 C30.1735132,38.1519493 29.8260308,38.4980756 29.8260308,38.9240773 C29.8260308,39.3500789 30.1735132,39.6968879 30.600342,39.6968879 C31.0271708,39.6968879 31.3739692,39.3500789 31.3739692,38.9240773 C31.3739692,38.4980756 31.0271708,38.1519493 30.600342,38.1519493 M30.600342,40.7209302 C29.6078282,40.7209302 28.8,39.9153503 28.8,38.9240773 C28.8,37.933487 29.6078282,37.127907 30.600342,37.127907 C31.5921718,37.127907 32.4,37.933487 32.4,38.9240773 C32.4,39.9153503 31.5921718,40.7209302 30.600342,40.7209302"
                  id="Fill-34"
                  fill="#0469D5"
                  mask="url(#mask-nmkf2eheoy-2)"
                ></path>
                <path
                  d="M11.400342,38.1519493 C10.9735132,38.1519493 10.6260308,38.4980756 10.6260308,38.9240773 C10.6260308,39.3500789 10.9735132,39.6968879 11.400342,39.6968879 C11.8271708,39.6968879 12.1739692,39.3500789 12.1739692,38.9240773 C12.1739692,38.4980756 11.8271708,38.1519493 11.400342,38.1519493 M11.400342,40.7209302 C10.4078282,40.7209302 9.6,39.9153503 9.6,38.9240773 C9.6,37.933487 10.4078282,37.127907 11.400342,37.127907 C12.3921718,37.127907 13.2,37.933487 13.2,38.9240773 C13.2,39.9153503 12.3921718,40.7209302 11.400342,40.7209302"
                  id="Fill-35"
                  fill="#0469D5"
                  mask="url(#mask-nmkf2eheoy-2)"
                ></path>
                <path
                  d="M10.84104,36.1757712 C9.640035,36.1757712 8.6625,37.1398375 8.6625,38.3255814 C8.6625,39.5113253 9.640035,40.4753916 10.84104,40.4753916 L50.35896,40.4753916 C51.559965,40.4753916 52.5375,39.5113253 52.5375,38.3255814 C52.5375,37.1398375 51.559965,36.1757712 50.35896,36.1757712 L10.84104,36.1757712 Z M50.35896,41.9186047 L10.84104,41.9186047 C8.83332,41.9186047 7.2,40.3068243 7.2,38.3255814 C7.2,36.3443385 8.83332,34.7325581 10.84104,34.7325581 L50.35896,34.7325581 C52.36668,34.7325581 54,36.3443385 54,38.3255814 C54,40.3068243 52.36668,41.9186047 50.35896,41.9186047 L50.35896,41.9186047 Z"
                  id="Fill-36"
                  fill="#0469D5"
                  mask="url(#mask-nmkf2eheoy-2)"
                ></path>
                <polygon
                  id="Fill-37"
                  fill="#0469D5"
                  mask="url(#mask-nmkf2eheoy-2)"
                  points="33.6 39.5232558 46.8 39.5232558 46.8 38.3255814 33.6 38.3255814"
                ></polygon>
                <polygon
                  id="Fill-38"
                  fill="#0469D5"
                  mask="url(#mask-nmkf2eheoy-2)"
                  points="14.4 39.5232558 27.6 39.5232558 27.6 38.3255814 14.4 38.3255814"
                ></polygon>
                <path
                  d="M42.2475574,32.0554384 L47.7536002,32.0554384 L47.7536002,26.6306081 L42.2475574,26.6306081 L42.2475574,32.0554384 Z M40.8,33.5348837 L49.2,33.5348837 L49.2,25.1511628 L40.8,25.1511628 L40.8,33.5348837 Z"
                  id="Fill-39"
                  fill="#0469D5"
                  mask="url(#mask-nmkf2eheoy-2)"
                ></path>
                <path
                  d="M27.8475574,32.0554384 L33.3536002,32.0554384 L33.3536002,26.6306081 L27.8475574,26.6306081 L27.8475574,32.0554384 Z M26.4,33.5348837 L34.8,33.5348837 L34.8,25.1511628 L26.4,25.1511628 L26.4,33.5348837 Z"
                  id="Fill-40"
                  fill="#0469D5"
                  mask="url(#mask-nmkf2eheoy-2)"
                ></path>
                <path
                  d="M13.4469786,32.0554384 L18.9530214,32.0554384 L18.9530214,26.6306081 L13.4469786,26.6306081 L13.4469786,32.0554384 Z M12,33.5348837 L20.4,33.5348837 L20.4,25.1511628 L12,25.1511628 L12,33.5348837 Z"
                  id="Fill-41"
                  fill="#0469D5"
                  mask="url(#mask-nmkf2eheoy-2)"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AssemblyIcon;
