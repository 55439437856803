import React from "react";

const MachineIcon: React.FC<IconProps> = ({ width }) => {
  return (
    <svg
      width={width || 50}
      viewBox="0 0 51 41"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <polygon
          id="path-wgqrc5n2sq-1"
          points="0 40.7209302 50.1173055 40.7209302 50.1173055 0.0762063123 0 0.0762063123"
        ></polygon>
      </defs>
      <g
        id="Homepage"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="jintai_website_mobile"
          transform="translate(-103.000000, -1637.000000)"
        >
          <g id="About" transform="translate(31.000000, 975.000000)">
            <g id="Group-20" transform="translate(72.000000, 522.000000)">
              <g id="Group-37" transform="translate(0.000000, 140.000000)">
                <polygon
                  id="Fill-1"
                  fill="#0469D5"
                  points="47.9069767 3.59302326 35.9302326 3.59302326 35.9302326 1.19767442 37.3102326 1.19767442 37.3102326 2.52175812 46.5269767 2.52175812 46.5269767 1.19767442 47.9069767 1.19767442"
                ></polygon>
                <polygon
                  id="Fill-2"
                  fill="#0469D5"
                  points="39.5232558 4.79069767 45.5116279 4.79069767 45.5116279 3.59302326 39.5232558 3.59302326"
                ></polygon>
                <polygon
                  id="Fill-3"
                  fill="#0469D5"
                  points="39.5232558 7.18604651 45.5116279 7.18604651 45.5116279 5.98837209 39.5232558 5.98837209"
                ></polygon>
                <polygon
                  id="Fill-4"
                  fill="#0469D5"
                  points="39.5232558 10.7790698 45.5116279 10.7790698 45.5116279 9.58139535 39.5232558 9.58139535"
                ></polygon>
                <polygon
                  id="Fill-5"
                  fill="#0469D5"
                  points="39.5232558 13.1744186 45.5116279 13.1744186 45.5116279 11.9767442 39.5232558 11.9767442"
                ></polygon>
                <polygon
                  id="Fill-6"
                  fill="#0469D5"
                  points="39.5232558 14.372093 45.5116279 14.372093 45.5116279 13.1744186 39.5232558 13.1744186"
                ></polygon>
                <g id="Group-36">
                  <path
                    d="M41.9042078,18.1707262 C41.49832,18.1707262 41.1681749,18.5028926 41.1681749,18.9112654 C41.1681749,19.3196381 41.49832,19.6518046 41.9042078,19.6518046 C42.3100955,19.6518046 42.6402407,19.3196381 42.6402407,18.9112654 C42.6402407,18.5028926 42.3100955,18.1707262 41.9042078,18.1707262 M41.9042078,21.1061235 C40.7009993,21.1061235 39.7227058,20.1218405 39.7227058,18.9112654 C39.7227058,17.701272 40.7009993,16.7164072 41.9042078,16.7164072 C43.1074163,16.7164072 44.0857098,17.701272 44.0857098,18.9112654 C44.0857098,20.1218405 43.1074163,21.1061235 41.9042078,21.1061235"
                    id="Fill-7"
                    fill="#0469D5"
                  ></path>
                  <path
                    d="M41.9042078,28.9045889 C41.49832,28.9045889 41.1681749,29.2367553 41.1681749,29.6451281 C41.1681749,30.0535008 41.49832,30.3856673 41.9042078,30.3856673 C42.3100955,30.3856673 42.6402407,30.0535008 42.6402407,29.6451281 C42.6402407,29.2367553 42.3100955,28.9045889 41.9042078,28.9045889 M41.9042078,31.8399862 C40.7009993,31.8399862 39.7227058,30.8557032 39.7227058,29.6451281 C39.7227058,28.434553 40.7009993,27.4502699 41.9042078,27.4502699 C43.1074163,27.4502699 44.0857098,28.434553 44.0857098,29.6451281 C44.0857098,30.8557032 43.1074163,31.8399862 41.9042078,31.8399862"
                    id="Fill-9"
                    fill="#0469D5"
                  ></path>
                  <path
                    d="M45.6590162,30.3722294 L43.363033,30.3722294 L43.363033,28.9179104 L44.2135471,28.9179104 L44.2135471,21.9621938 C44.2135471,21.0989101 43.7423241,20.311251 42.9831638,19.9069503 L43.6596433,18.6219141 C44.8929176,19.2792662 45.6590162,20.5590669 45.6590162,21.9621938 L45.6590162,30.3722294 Z"
                    id="Fill-11"
                    fill="#0469D5"
                  ></path>
                  <path
                    d="M40.4457294,30.3722294 L38.1491681,30.3722294 L38.1491681,21.9621938 C38.1491681,20.5573217 38.9164231,19.2769393 40.1514319,18.6207506 L40.8267551,19.9063686 C40.0670165,20.3106692 39.5946372,21.0983284 39.5946372,21.9621938 L39.5946372,28.9179104 L40.4457294,28.9179104 L40.4457294,30.3722294 Z"
                    id="Fill-13"
                    fill="#0469D5"
                  ></path>
                  <path
                    d="M44.9554775,36.3414524 L43.9332417,35.312958 C45.0520348,34.1873151 45.0520348,32.3560367 43.9332417,31.2303939 C43.6638063,30.9593088 43.3492722,30.7481417 43.0000468,30.6027098 L43.552216,29.2589191 C44.0772104,29.477067 44.5495897,29.7941085 44.9548993,30.2013178 C46.6374253,31.8947268 46.6374253,34.6486251 44.9554775,36.3414524"
                    id="Fill-15"
                    fill="#0469D5"
                  ></path>
                  <path
                    d="M38.8534585,36.3414524 C38.0382139,35.5217982 37.5895403,34.431059 37.5895403,33.2716759 C37.5895403,32.1122929 38.0382139,31.0215537 38.8534585,30.2018995 C39.2576116,29.795272 39.7294128,29.4776487 40.2538289,29.2595009 L40.8059982,30.6038733 C40.4579292,30.7481417 40.1445515,30.9593088 39.8756942,31.2303939 C39.3333542,31.7754726 39.0350094,32.5003052 39.0350094,33.2716759 C39.0350094,34.0430467 39.3333542,34.7678793 39.8756942,35.312958 L38.8534585,36.3414524 Z"
                    id="Fill-17"
                    fill="#0469D5"
                  ></path>
                  <path
                    d="M43.6122319,29.6450699 L42.7449504,29.6450699 L42.7449504,23.9976586 C42.7449504,23.5311131 42.3679721,23.1518267 41.9042656,23.1518267 C41.4405591,23.1518267 41.0635808,23.5311131 41.0635808,23.9976586 L41.0635808,29.6450699 L40.1962993,29.6450699 L40.1962993,23.9976586 C40.1962993,23.0500244 40.9623979,22.2792353 41.9042656,22.2792353 C42.8461333,22.2792353 43.6122319,23.0500244 43.6122319,23.9976586 L43.6122319,29.6450699 Z"
                    id="Fill-19"
                    fill="#0469D5"
                  ></path>
                  <path
                    d="M27.9836463,28.245317 C27.0805172,28.245317 26.2317377,28.5984256 25.5945749,29.2389077 C24.9579903,29.8799715 24.6070304,30.7333658 24.6070304,31.642606 C24.6070304,32.4040874 24.8527601,33.1271748 25.3077938,33.71821 L26.3832229,32.635615 C25.936862,31.9067104 26.0270593,30.9363888 26.6543929,30.3057961 C27.3748147,29.5809635 28.5924779,29.5815452 29.3128997,30.3052144 C29.6673287,30.6623951 29.8627561,31.1370848 29.8627561,31.642606 C29.8627561,31.999205 29.7656206,32.3406791 29.5840697,32.6361967 L30.6594987,33.71821 C31.6817345,32.3860539 31.5863335,30.4599539 30.3727176,29.2389077 C29.7355549,28.5984256 28.8873536,28.245317 27.9836463,28.245317 L27.9836463,28.245317 Z M30.6791571,34.971833 L28.3930031,32.6716821 L28.6994426,32.3633665 C28.8902445,32.1713964 28.9954747,31.9154363 28.9954747,31.642606 C28.9954747,31.3703575 28.8902445,31.1143974 28.6994426,30.9224273 C28.324777,30.5466313 27.6425156,30.5460496 27.26785,30.9224273 C26.8729478,31.3197472 26.8729478,31.9660466 27.26785,32.3633665 L27.5742894,32.6716821 L25.2881354,34.971833 L24.981696,34.6635173 C24.1803279,33.8578247 23.7397489,32.785119 23.7397489,31.642606 C23.7397489,30.5000931 24.1803279,29.4279692 24.981696,28.6222765 C25.7819077,27.8165838 26.8480857,27.3727256 27.9836463,27.3727256 C29.1192068,27.3727256 30.1853848,27.8165838 30.9855966,28.6222765 C32.641526,30.2877625 32.641526,32.9974496 30.9855966,34.6635173 L30.6791571,34.971833 Z"
                    id="Fill-21"
                    fill="#0469D5"
                  ></path>
                  <mask id="mask-wgqrc5n2sq-2" fill="white">
                    <use xlinkHref="#path-wgqrc5n2sq-1"></use>
                  </mask>
                  <g id="Clip-24"></g>
                  <polygon
                    id="Fill-23"
                    fill="#0469D5"
                    mask="url(#mask-wgqrc5n2sq-2)"
                    points="0.722734563 40.7209302 49.6773047 40.7209302 49.6773047 39.2666113 0.722734563 39.2666113"
                  ></polygon>
                  <path
                    d="M3.91912935,1.5304089 C2.55518468,1.5304089 1.44564258,2.64674412 1.44564258,4.01903947 C1.44564258,5.39191654 2.55518468,6.50825176 3.91912935,6.50825176 L20.0170299,6.50825176 C21.3809746,6.50825176 22.4910949,5.39191654 22.4910949,4.01903947 C22.4910949,2.64674412 21.3809746,1.5304089 20.0170299,1.5304089 L3.91912935,1.5304089 Z M20.0170299,7.9625707 L3.91912935,7.9625707 C1.75786391,7.9625707 0.000173456295,6.19353714 0.000173456295,4.01903947 C0.000173456295,1.84454179 1.75786391,0.0760899668 3.91912935,0.0760899668 L20.0170299,0.0760899668 C22.1782953,0.0760899668 23.936564,1.84454179 23.936564,4.01903947 C23.936564,6.19353714 22.1782953,7.9625707 20.0170299,7.9625707 L20.0170299,7.9625707 Z"
                    id="Fill-25"
                    fill="#0469D5"
                    mask="url(#mask-wgqrc5n2sq-2)"
                  ></path>
                  <path
                    d="M4.74113873,39.2666113 L10.6970497,39.2666113 L10.6970497,7.96268704 L4.74113873,7.96268704 L4.74113873,39.2666113 Z M3.29566961,40.7209302 L12.1425188,40.7209302 L12.1425188,6.50836811 L3.29566961,6.50836811 L3.29566961,40.7209302 Z"
                    id="Fill-26"
                    fill="#0469D5"
                    mask="url(#mask-wgqrc5n2sq-2)"
                  ></path>
                  <polygon
                    id="Fill-27"
                    fill="#0469D5"
                    mask="url(#mask-wgqrc5n2sq-2)"
                    points="28.7063808 9.71863173 27.2609117 9.71863173 27.2609117 4.74660615 23.2135982 4.74660615 23.2135982 3.29228721 28.7063808 3.29228721"
                  ></polygon>
                  <path
                    d="M27.9836463,10.4457912 C27.0475605,10.4457912 26.2860873,11.2119264 26.2860873,12.1537434 C26.2860873,13.0949786 27.0475605,13.8611138 27.9836463,13.8611138 C28.9197321,13.8611138 29.6812052,13.0949786 29.6812052,12.1537434 C29.6812052,11.2119264 28.9197321,10.4457912 27.9836463,10.4457912 M27.9836463,15.3154327 C26.2508179,15.3154327 24.8406182,13.8971809 24.8406182,12.1537434 C24.8406182,10.4103058 26.2508179,8.99147226 27.9836463,8.99147226 C29.7164747,8.99147226 31.1266743,10.4103058 31.1266743,12.1537434 C31.1266743,13.8971809 29.7164747,15.3154327 27.9836463,15.3154327"
                    id="Fill-28"
                    fill="#0469D5"
                    mask="url(#mask-wgqrc5n2sq-2)"
                  ></path>
                  <path
                    d="M27.9836463,21.7000092 C27.0475605,21.7000092 26.2860873,22.4661444 26.2860873,23.4079614 C26.2860873,24.3491966 27.0475605,25.1153318 27.9836463,25.1153318 C28.9197321,25.1153318 29.6812052,24.3491966 29.6812052,23.4079614 C29.6812052,22.4661444 28.9197321,21.7000092 27.9836463,21.7000092 M27.9836463,26.5696507 C26.2508179,26.5696507 24.8406182,25.1513989 24.8406182,23.4079614 C24.8406182,21.6639421 26.2508179,20.2456903 27.9836463,20.2456903 C29.7164747,20.2456903 31.1266743,21.6639421 31.1266743,23.4079614 C31.1266743,25.1513989 29.7164747,26.5696507 27.9836463,26.5696507"
                    id="Fill-29"
                    fill="#0469D5"
                    mask="url(#mask-wgqrc5n2sq-2)"
                  ></path>
                  <polygon
                    id="Fill-30"
                    fill="#0469D5"
                    mask="url(#mask-wgqrc5n2sq-2)"
                    points="27.2609695 20.9730243 28.7064387 20.9730243 28.7064387 14.5885641 27.2609695 14.5885641"
                  ></polygon>
                  <path
                    d="M4.01851981,3.33498601 C3.64385421,3.33498601 3.33857113,3.64213817 3.33857113,4.01909764 C3.33857113,4.39663884 3.64385421,4.703791 4.01851981,4.703791 C4.3931854,4.703791 4.69846848,4.39663884 4.69846848,4.01909764 C4.69846848,3.64213817 4.3931854,3.33498601 4.01851981,3.33498601 M4.01851981,5.57638236 C3.16511484,5.57638236 2.47128966,4.87772754 2.47128966,4.01909764 C2.47128966,3.16104947 3.16511484,2.46239465 4.01851981,2.46239465 C4.87192478,2.46239465 5.56574996,3.16104947 5.56574996,4.01909764 C5.56574996,4.87772754 4.87192478,5.57638236 4.01851981,5.57638236"
                    id="Fill-31"
                    fill="#0469D5"
                    mask="url(#mask-wgqrc5n2sq-2)"
                  ></path>
                  <path
                    d="M19.9175238,3.33498601 C19.5428582,3.33498601 19.2375751,3.64213817 19.2375751,4.01909764 C19.2375751,4.39663884 19.5428582,4.703791 19.9175238,4.703791 C20.2921894,4.703791 20.5974725,4.39663884 20.5974725,4.01909764 C20.5974725,3.64213817 20.2921894,3.33498601 19.9175238,3.33498601 M19.9175238,5.57638236 C19.0641188,5.57638236 18.3702937,4.87772754 18.3702937,4.01909764 C18.3702937,3.16104947 19.0641188,2.46239465 19.9175238,2.46239465 C20.7709288,2.46239465 21.464754,3.16104947 21.464754,4.01909764 C21.464754,4.87772754 20.7709288,5.57638236 19.9175238,5.57638236"
                    id="Fill-32"
                    fill="#0469D5"
                    mask="url(#mask-wgqrc5n2sq-2)"
                  ></path>
                  <path
                    d="M6.96617827,7.67176508 L6.96617827,18.3720621 C6.96617827,18.7897425 7.30441804,19.1294714 7.71955678,19.1294714 C8.13469551,19.1294714 8.4723571,18.7897425 8.4723571,18.3720621 L8.4723571,7.67176508 L6.96617827,7.67176508 Z M7.71955678,20.0020628 C6.82567867,20.0020628 6.09889679,19.2708312 6.09889679,18.3720621 L6.09889679,6.79917372 L9.33963857,6.79917372 L9.33963857,18.3720621 C9.33963857,19.2708312 8.6128567,20.0020628 7.71955678,20.0020628 L7.71955678,20.0020628 Z"
                    id="Fill-33"
                    fill="#0469D5"
                    mask="url(#mask-wgqrc5n2sq-2)"
                  ></path>
                  <path
                    d="M6.96617827,39.5574751 L8.4723571,39.5574751 L8.4723571,28.8565963 C8.4723571,28.4389159 8.13469551,28.099187 7.71955678,28.099187 C7.30441804,28.099187 6.96617827,28.4389159 6.96617827,28.8565963 L6.96617827,39.5574751 Z M9.33963857,40.4300664 L6.09889679,40.4300664 L6.09889679,28.8565963 C6.09889679,27.958409 6.82567867,27.2265957 7.71955678,27.2265957 C8.6128567,27.2265957 9.33963857,27.958409 9.33963857,28.8565963 L9.33963857,40.4300664 Z"
                    id="Fill-34"
                    fill="#0469D5"
                    mask="url(#mask-wgqrc5n2sq-2)"
                  ></path>
                  <polygon
                    id="Fill-35"
                    fill="#0469D5"
                    mask="url(#mask-wgqrc5n2sq-2)"
                    points="20.0168565 1.53052525 50.1173055 1.53052525 50.1173055 0.0762063123 20.0168565 0.0762063123"
                  ></polygon>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default MachineIcon;
